body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@import url("https://fonts.cdnfonts.com/css/arcade-classic");

* {
  font-family: "ArcadeClassic", sans-serif !important;
  word-spacing: 0.2rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
blockquote {
  color: #fff;
}

.App {
  position: relative;
}

.hide {
  opacity: 0;
}

.show {
  animation-name: show;
  animation-duration: 3s;
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
