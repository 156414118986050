/*.App {
  text-align: center;
}*/

html

html {
  --text-color: #ecf0f1;
  --action-color: #e74c3c;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#root-2 {
  height: 500px;

  @media (max-width: 575px) {
    height: 280px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.background-image {
  /* background-image: url("../src/assets/images/game-background-2.jpg"),
    url("../src/assets/images/game-background-2.jpg"); */
  background-image: url("../src/assets/images/game-background-2.jpg");
  background-repeat: no-repeat, no-repeat;
  background-size: 100%, 100%;
  /* background-position: 7.5% top, 92.5% top; */
  background-position: center;
  background-color: black;
}

.page {
  width: 100%;
  height: 100%;
  /* background: #2c3e50; */
}

.scene-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scene-container svg {
  background-color: rgba(0, 0, 0, 0.7);
}

.scene {
  border: 2px solid var(--text-color);
}

.block {
  stroke-width: 1px;
  stroke: var(--text-color);
}

.paddle {
  /* fill: #3498db; */
  fill: #ecd39f;
}

.ball {
  fill: #e74c3c;
}

.level {
  font-weight: bold;
  fill: var(--text-color);
}

.life {
  fill: #e74c3c;
}
